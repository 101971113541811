import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Snackbar components can be used to inform users of the results of the process executed by an app. The snackbar type exposed at the bottom is used by default`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/guideline-snackbar.svg",
      "alt": "guideline snackbar"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Text Label :`}</strong>{` Contain a text label that directly relates to the process being performed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Button (Optional) :`}</strong>{` To provide an action for user to take an action if needed`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Snackbar informs users of a process that an app has performed or will perform. Do not place core app functionality into a Snackbar.
Snackbars appear above all other elements on screen and only one can be displayed at a time.`}</p>
    <p><strong parentName="p">{`Common places buttons appear :`}</strong>{` Bottom of the screen, top of the screen (Rarely).`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> To display temporary short messages.</li>
      <li> To react to user input (let users know something is happening). </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> If the message should stay on the screen </li>
      <li> When you want to include an action </li>
      <li> When an action is necessary before continuing</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`A modal displays important information to a user without requiring them to navigate to a new page. Keyboard and mouse users shouldn’t be able to interact with the rest of the page outside of an open modal. Set initial focus on the first focusable element in the modal. Keyboard users should be able to leave the modal by pressing Esc and focus should return to the element that invoked the modal.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use different copy to provide multiple information to users" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/guideline-snackbar-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use multiple snackbar at same time " aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/guideline-snackbar-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Snackbar :`}</p>
    <ul>
      <li parentName="ul">{`Snackbars should be located 24px from the bottom-left corner of the screen.`}</li>
      <li parentName="ul">{`Only one snackbar should be visible at a time`}</li>
      <li parentName="ul">{`If multiple snackbars need to appear, have them appear sequentially, replacing the previous snackbar`}</li>
      <li parentName="ul">{`Avoid using snackbars for critical information It may be difficult for users with low dexterity or vision to access it.`}</li>
      <li parentName="ul">{`Avoid including user-inputted text`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      